import {createSlice} from '@reduxjs/toolkit'

const countrySlice = createSlice({
  name: 'countryCode',
  initialState: {
    countryCode: '-',
  },
  reducers: {
    setCountryCodeStateAction(state, {payload}) {
      state.countryCode = payload
    },
  },
})

export const {setCountryCodeStateAction} = countrySlice.actions
export default countrySlice.reducer
