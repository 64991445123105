import './style.css'

import {API} from 'aws-amplify'
import React, {useEffect, useState} from 'react'
import {shallowEqual} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {useScript} from 'usehooks-ts'

import appleLogo from '../../assert/icon/text_apple.svg'
import {useAppSelector} from '../../store'
import {createId} from '../common/createId'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const MusicKit: any

const musicKitJs = 'https://js-cdn.music.apple.com/musickit/v1/musickit.js'
interface PropsInfo {
  readonly id: string
  readonly disableStatus: boolean
  readonly developerToken: string
}

export const Apple = (props: PropsInfo) => {
  const [disableValue, setDisableValue] = useState(props.disableStatus)
  useScript(musicKitJs)

  const navigate = useNavigate()
  const pspaId = props.id

  if (pspaId === null) {
    navigate('/error')
  }

  const {countryCode} = useAppSelector(
    (state) => ({
      countryCode: state.countryCode.countryCode,
    }),
    shallowEqual
  )

  const handleAppleLogin = () => {
    //
    for (let i = 0; i <= localStorage.length; i++) {
      if (localStorage.key(i) != null) {
        if (localStorage.key(i)?.startsWith('music.') && localStorage.key(i)?.endsWith('.u')) {
          const key = localStorage.key(i)
          if (typeof key === 'string') {
            localStorage.removeItem(key)
          }
        }
      }
    }
    //
    const idUserMng = createId()
    //setTdData(pspaId, idUserMng, 'apple')

    const appleDeveloperToken = props.developerToken
    MusicKit.configure({
      developerToken: appleDeveloperToken,
      app: {
        name: 'sony music',
        build: '01',
      },
    })
    const music = MusicKit.getInstance()
    music.authorize().then(function (token: string) {
      const appleMyInit = {
        queryStringParameters: {
          // OPTIONAL
          uniqueid: pspaId,
          developerToken: appleDeveloperToken,
          musicUserToken: token,
          usermngid: idUserMng,
          countrySelect: countryCode,
        },
      }
      API.get('appleLogin', '/paps', appleMyInit)
        .then(() => {
          const completeUrl = '/complete?id=' + pspaId + '&user=' + idUserMng
          navigate(completeUrl)
        })
        .catch(() => {
          navigate('/error')
        })
    })
  }
  useEffect(() => {
    setDisableValue(props.disableStatus)
  }, [props.disableStatus])

  return (
    <button
      className={'p-reservebtn__items' + ' ' + (props.disableStatus ? 'is-apple-disabled' : 'is-apple')}
      onClick={handleAppleLogin}
      disabled={disableValue}
    >
      <p
        className={
          (disableValue ? 'p-reservebtn__items-is-inner-disabled' : 'p-reservebtn__items-is-inner') +
          ' ' +
          'is-inner-apple'
        }
      >
        <span>
          <img src={appleLogo} className={'is-inner-apple-span-image'} alt="Pre-Add on Apple Music" />
        </span>
      </p>
    </button>
  )
}
