import {RouteObject} from 'react-router-dom'

import {CompletePage} from '../pages/complete'
import {ErrorPage} from '../pages/error'
import {HomeEN} from '../pages/home/en'

const routers: RouteObject[] = [
  {
    path: '/',
    element: <ErrorPage />,
  },
  {
    path: '/lp',
    element: <HomeEN />,
  },
  {
    path: '/complete',
    element: <CompletePage />,
  },
  {
    path: '/error',
    element: <ErrorPage />,
  },
]

export default routers
