import './index.css'
import '@aws-amplify/ui-react/styles.css'

import {Amplify} from 'aws-amplify'
import React from 'react'
import ReactDOM from 'react-dom/client'
import {HelmetProvider} from 'react-helmet-async'
import {Provider} from 'react-redux'
import {BrowserRouter} from 'react-router-dom'

import App from './App'
import config from './aws-exports'
import store from './store'

Amplify.configure(config)

Amplify.configure({
  API: {
    endpoints: [
      {
        name: 'appleToken',
        endpoint: process.env.REACT_APP_GET_DEVTOKEN_ENDPOINT,
      },
      {
        name: 'appleLogin',
        endpoint: process.env.REACT_APP_LOGIN_ENDPOINT,
      },
      {
        name: 'getAlbumInfo',
        endpoint: process.env.REACT_APP_GET_CONTENT_ENDPOINT,
      },
    ],
  },
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <HelmetProvider>
          <App />
        </HelmetProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
)
