import {Helmet} from 'react-helmet-async'

interface PropsInfo {
  readonly title?: string
}

export const DynamicTitle = (props: PropsInfo) => {
  return (
    <Helmet>
      <title>{props.title ?? 'Pre-Add/Pre-Save Service information'}</title>
    </Helmet>
  )
}
