import React from 'react'

import instagramImg1 from './warn_instagram_en_img_1.jpg'
import instagramImg2 from './warn_instagram_en_img_2.jpg'

interface PropsInfo {
  readonly title: string
  readonly date_release: string
  readonly caption: string
  readonly isNoneJK: boolean
}

export const SubEnIndex = (props: PropsInfo) => {
  return (
    <>
      <div className="none-jk-instagram-p-info-title">
        <h1>
          <span className="none-jk-instagram-p-info-span">{props.title}</span>
        </h1>
        <div className="none-jk-instagram-p-info-release-date">{props.date_release} RELEASE</div>
      </div>
      <div className="none-jk-instagram-p-info-lead">
        <p>{props.caption}</p>{' '}
      </div>
      <hr className="is-border" />
      <div>
        <div className="p-info-reserve-instagram__lead">
          <p>
            If you are using the Instagram app, open the web page in your default browser by tapping the three dots icon
            in the top-right corner and selecting “Open in browser.”
          </p>
        </div>
        <div>
          <div className="p-info-instagram-screen">
            <img className="p-info-instagram__reserve-screen-img" src={instagramImg1} alt="" />
          </div>
          <div className="p-info-instagram-screen">
            <img className="p-info-instagram__reserve-screen-img" src={instagramImg2} alt="" />
          </div>
          <div className="p-info-instagram__reserve-screen-lead">
            The actual display may vary depending on the version of the app or OS you are using.
          </div>
        </div>
        <div className="p-info-instagram__reserve-cookie">
          <a className="ot-sdk-show-settings" href="#ot-sdk-btn">
            Cookies Settings
          </a>
        </div>
      </div>
    </>
  )
}
