export interface DateInfo {
  readonly inputDate: string
  readonly inputCountry?: string
}

export const formatDate = (dateInfo: DateInfo): string => {
  let result: string
  const [year, month, day] = dateInfo.inputDate.split('.')
  switch (dateInfo.inputCountry) {
    case 'US': {
      const monthNames: string[] = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ]
      const monthName: string = monthNames[parseInt(month, 10) - 1]

      result = `${monthName} ${parseInt(day, 10)}, ${year}`
      break
    }
    default:
      result = dateInfo.inputDate
  }
  return result
}
