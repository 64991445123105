import '../style.css'

import {View} from '@aws-amplify/ui-react'
import {API} from 'aws-amplify'
import React, {useEffect, useState} from 'react'
import {shallowEqual} from 'react-redux'
import {useSearchParams} from 'react-router-dom'
import {useNavigate} from 'react-router-dom'

import {Apple} from '../../../components/apple'
import {OptanonWrapper} from '../../../components/common/oneTrust'
import {DynamicTitle} from '../../../components/dynamicTitle'
import {SelectList} from '../../../components/select'
import {Spotify} from '../../../components/spotify'
import {Title} from '../../../components/title'
import {useAppSelector} from '../../../store'
import {DateInfo, formatDate} from '../../../utils/formatDate'
import {Anime} from '../../animation/index'
import {Instagram} from '../../instagram'
import {HomeCheckList} from './checklist'
import {HomeKR} from './kr'
import {KR_POLICY} from './kr/text'
import {HomeUS} from './us'
import {US_POLICY} from './us/text'

export const HomeEN = () => {
  const [searchParams] = useSearchParams()
  const id = searchParams.get('id')
  const [title, setTitle] = useState('')
  const [initReleaseDate, setInitReleaseDate] = useState('')
  const [releaseDate, setReleaseDate] = useState('')
  const [caption, setCaption] = useState('')
  const [albumImage, setAlbumImage] = useState('')
  const navigate = useNavigate()
  const [isNoneJK, setIsNoneJK] = useState(false)
  const [show, setShow] = useState(false)
  const [disableValue, setDisableValue] = useState(true)
  const [isInstagram, setInstagram] = useState(false)
  const [developerToken, setDeveloperToken] = useState('')
  const {countryCode, checkListValue} = useAppSelector(
    (state) => ({
      countryCode: state.countryCode.countryCode,
      checkListValue: state.checkListValue.checkValue,
    }),
    shallowEqual
  )

  const renderChecklist = () => {
    switch (countryCode) {
      case 'us':
        return <HomeCheckList policy={US_POLICY} />
      case 'kr':
        return <HomeCheckList policy={KR_POLICY} />
      default:
        return <HomeCheckList policy={US_POLICY} />
    }
  }

  const renderHome = () => {
    switch (countryCode) {
      case 'US':
        return <HomeUS />
      case 'KR':
        return <HomeKR />
      default:
        return <HomeUS />
    }
  }

  const renderButton = () => {
    return (
      <>
        <Apple id={id as string} disableStatus={disableValue} developerToken={developerToken} />
        <Spotify id={id as string} disableStatus={disableValue} />
      </>
    )
  }

  const handlerInfo = () => {
    OptanonWrapper()
  }

  const ensureInstagram = () => {
    const ns = window.navigator.userAgent.toLowerCase().trim()
    if (ns.includes('instagram')) {
      if (ns.includes('iphone') || ns.includes('ipod') || ns.includes('ipad') || ns.includes('android')) {
        setInstagram(true)
      }
    }
  }

  useEffect(() => {
    if (id === null) {
      navigate('/error')
    }

    // check whether access is from instagram
    ensureInstagram()
    handlerInfo()
    const myInit = {
      queryStringParameters: {
        // OPTIONAL
        uniqueid: id,
      },
    }
    API.get('getAlbumInfo', '/paps', myInit)
      .then((response) => {
        if (!response['en_text_title']) {
          navigate('/error')
        }
        // To replace text_title to en_title *important
        setTitle(response['en_text_title'])
        setInitReleaseDate(response['text_date_release'])

        // setReleaseDate(response['text_date_release'])
        const dateInfo: DateInfo = {
          inputDate: response['text_date_release'],
          inputCountry: 'US',
        }
        const releaseDate = formatDate(dateInfo)
        setReleaseDate(releaseDate)

        setCaption(response['en_text_caption'])
        if (response['url_image_s3'] === '') {
          setIsNoneJK(true)
        }
        setAlbumImage(response['url_image_s3'])
        setShow(true)
      })
      .catch(() => {
        navigate('/error')
      })

    API.get('appleToken', '/paps', myInit)
      .then((response) => {
        setDeveloperToken(response)
      })
      .catch(() => {
        navigate('/error')
      })
  }, [id])

  useEffect(() => {
    //
    // if (countryCode !== '-' && countryCode !== '') {
    //   const dateInfo: DateInfo = {
    //     inputDate: initReleaseDate,
    //     inputCountry: 'US',
    //   }
    //   const releaseDate = formatDate(dateInfo)
    //   setReleaseDate(releaseDate)
    // }

    if (checkListValue && countryCode !== '-' && countryCode !== '') {
      setDisableValue(false)
    } else {
      setDisableValue(true)
    }
  }, [countryCode, checkListValue])

  return (
    <>
      {show ? (
        isNoneJK ? (
          isInstagram ? (
            <Instagram
              imageUrl={albumImage}
              title={title}
              date_release={releaseDate}
              caption={caption}
              isNoneJK={true}
            />
          ) : (
            <>
              <DynamicTitle title={title} />
              <div className="container">
                <div className="none-jk-home-is-load">
                  <div className="none-jk-home-l-en-wrap">
                    <div className="none-jk-home-pull-down_language">
                      <div className="pull-down_language__inner">
                        <SelectList />
                      </div>
                    </div>
                    <div className="none-jk-home-p-info">
                      <div className="none-jk-home-p-info-inner">
                        <Title title={title} caption={caption} releaseDate={releaseDate} isNoneJK={true} />
                        <hr className="is-home-border" />
                        {renderHome()}
                        <ul className="p-info__reserve-home-checklist">
                          <div className="p-info__reserve-home-checklist-items">{renderChecklist()}</div>
                        </ul>
                        <div className="none-jk-home-p-reservebtn">{renderButton()}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )
        ) : isInstagram ? (
          <Instagram
            imageUrl={albumImage}
            title={title}
            date_release={releaseDate}
            caption={caption}
            isNoneJK={false}
          />
        ) : (
          <>
            <DynamicTitle title={title} />
            <div className="is-load-home">
              {/* アルバム画像 */}
              <div className="p-bg-home">
                <div className="p-bg-home__img" style={{backgroundImage: `url(${albumImage})`}}></div>
              </div>
              <div className="l-wrap-home">
                <div className="p-hero-home">
                  <div className="p-hero__img-home">
                    <Anime imageUrl={albumImage} />
                  </div>
                </div>
                <div className="pull-down_language">
                  <div className="pull-down_language__inner">
                    <SelectList />
                  </div>
                </div>
                <div className="p-info-home ">
                  <div className="p-info-home__inner">
                    <Title title={title} caption={caption} releaseDate={releaseDate} isNoneJK={false} />
                    <hr className="is-home-border" />
                    {renderHome()}
                    <ul className="p-info__reserve-home-checklist">
                      <div className="p-info__reserve-home-checklist-items">{renderChecklist()}</div>
                    </ul>
                    <View width={{base: '92%', xxl: '60% '}} margin={{base: '0 auto'}}>
                      {renderButton()}
                    </View>
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      ) : (
        <></>
      )}
    </>
  )
}
