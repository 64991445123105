export const LEAD_FIRST = 'X!개인정보보호정책 및 이용약관에 동의합니다'
export const LEAD_SECOND = '개인정보보호정책 및 이용약관에 동의합니다'
export const NOTE_FIRST = '개인정보보호정책 및 이용약관에 동의합니다'
export const NOTE_SECOND = '개인정보보호정책 및 이용약관에 동의합니다'
export const NOTE_THIRD = '개인정보보호정책 및 이용약관에 동의합니다'
export const NOTE_FOURTH = '개인정보보호정책 및 이용약관에 동의합니다'
export const NOTE_FIFTH = ' 개인정보보호정책 및 이용약관에 동의합니다'
export const NOTE_SIXTH = '개인정보보호정책 및 이용약관에 동의합니다'
export const NOTE_SEVENTH = '개인정보보호정책 및 이용약관에 동의합니다'
export const NOTE_EIGHTH = '개인정보보호정책 및 이용약관에 동의합니다'
export const NOTE_NINTH = '개인정보보호정책 및 이용약관에 동의합니다'
export const KR_POLICY = '개인정보보호정책 및 이용약관에 동의합니다.'
