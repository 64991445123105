import './style.css'

import {Divider, Flex, View} from '@aws-amplify/ui-react'
import React from 'react'

import {DynamicTitle} from '../../components/dynamicTitle'

export const ErrorPage = () => {
  return (
    <>
      <DynamicTitle />
      <View>
        {/* 背景 */}
        <View
          style={{
            width: '100vw',
            height: '100vh',
            backgroundColor: '#f3f3f3',
            zIndex: '0',
            position: 'fixed',
          }}
        ></View>

        <Flex justifyContent="center" alignItems="center" alignContent="center" position="relative" gap="0">
          {/* フォーム */}
          <Flex
            width="40rem"
            direction="column"
            alignItems="center"
            backgroundColor="white"
            justifyContent="flex-start"
            height="100%"
            padding={{base: '2%', medium: '5%'}}
            margin="5%"
            style={{fontFamily: 'Noto Sans JP'}}
          >
            <View>An error has occurred.</View>

            <Divider size="small" orientation="horizontal" margin="1rem" marginBottom="5rem" />

            <View marginBottom="2rem">
              <a href="https://www.sonymusic.co.jp/">Back to top page</a>
            </View>
          </Flex>
        </Flex>
      </View>
    </>
  )
}
