import './style.css'

import { Divider, Flex, View } from '@aws-amplify/ui-react'
import { API } from 'aws-amplify'
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

import { DateInfo, formatDate } from '../../utils/formatDate'

export const CompletePage = () => {
  const [searchParams] = useSearchParams()
  const id = searchParams.get('id')
  const idUserMng = searchParams.get('user')
  const lang = searchParams.get('lang')
  const [title, setTitle] = useState('')
  const [caption, setCaption] = useState('')
  const [userId, setUserId] = useState('')
  const [releaseDate, setReleaseDate] = useState('')

  const [digitokuUrl, setDigitokuUrl] = useState('')
  const [redirectCount, setRedirectCount] = useState(10)
  const [closePageText, setClosePageText] = useState('')

  const navigate = useNavigate()

  useEffect(() => {
    if (!id) {
      navigate('/error')
    }

    const myInit = {
      queryStringParameters: {
        uniqueid: id
      }
    }
    API.get('getAlbumInfo', '/paps', myInit)
      .then((response) => {
        setTitle(response['en_text_title'])
        setCaption(response['en_text_caption'])
        setUserId('Reception ID : ' + idUserMng)

        setDigitokuUrl(response['en_url_digitoku'])
        setClosePageText("Please close this screen.")

        const dateInfo: DateInfo = {
          inputDate: response['text_date_release'],
          inputCountry: 'US',
        }

        const releaseDate = formatDate(dateInfo)

        setReleaseDate(
          'Your pre-subscription has been completed.\n' +
          'The scheduled release date is ' +
          releaseDate +
          ' \n \n' +
          'The piece of music will be available on Spotify or Apple Music on its scheduled release date; all you need to do is wait until then!\n \n '
        )
      })
      .catch(() => {
        navigate('/error')
      })
  }, [id])

  useEffect(() => {

    if (redirectCount > 0) {
      const timer = setTimeout(() => {
        console.log("count down")
        setRedirectCount(redirectCount - 1)
      }, 1000)
    } else {
      console.log("count down end")
      if (digitokuUrl) {
        window.location.href = digitokuUrl
      }
    }

  }, [redirectCount])

  return (
    <View>
      {/* 背景 */}
      <View
        style={{
          width: '100vw',
          height: '100vh',
          backgroundColor: '#f3f3f3',
          zIndex: '0',
          position: 'fixed',
        }}
      ></View>

      <Flex justifyContent="center" alignItems="center" alignContent="center" position="relative" gap="0">
        {/* フォーム */}
        <Flex
          width="40rem"
          direction="column"
          alignItems="center"
          backgroundColor="white"
          justifyContent="flex-start"
          height="100%"
          padding={{ base: '2%', medium: '5%' }}
          margin="5%"
          style={{ fontFamily: 'Noto Sans JP' }}
        >
          <View>
            <div className="p-info__title">
              <View className="p-info__song">{title}</View>
            </div>
            <View className="p-info__lead">{caption}</View>
          </View>

          <Divider size="small" orientation="horizontal" margin="1rem" />

          <View className="p-info__lead">{userId}</View>

          <View marginBottom="5rem">
            <View className="p-info__reserve-lead">
              <p>
                {releaseDate.split('\n').map((line, i) => {
                  return (
                    <React.Fragment key={i}>
                      {line}
                      <br />
                    </React.Fragment>
                  )
                })}
              </p>

              {/* デジタル特典リダイレクト */}
              {!digitokuUrl ?
                (
                  <p>
                    {closePageText}
                  </p>
                )
                : (
                  <View  >
                    <p className="p-redirect_count">
                      You will be redirected to the digital rewards page in {redirectCount} seconds.
                    </p>
                    <a href={digitokuUrl}>{"Click here if you don't want to be automatically redirected."}</a>
                  </View>
                )}

            </View>
          </View>
        </Flex>
      </Flex>
    </View>
  )
}
