var anonymousMode = 0

export function OptanonWrapper() {
  if (window.OneTrust !== undefined) {
    window.OneTrust.OnConsentChanged(() => {
      const arrayData = window.OnetrustActiveGroups.split(',')
      if (arrayData.length === 3) {
        anonymousMode = 1
      } else {
        anonymousMode = 2
      }
    })
    return anonymousMode
  }
}
