import {configureStore} from '@reduxjs/toolkit'
import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux'

import checkListReducer from './features/checkList'
import countryCodeReducer from './features/country'

const store = configureStore({
  reducer: {
    countryCode: countryCodeReducer,
    checkListValue: checkListReducer,
  },
  devTools: false,
})

type GetStateFnType = typeof store.getState
export type IRootState = ReturnType<GetStateFnType>

type AppDispatch = typeof store.dispatch
type DispatchFunc = () => AppDispatch

export const useAppSelector: TypedUseSelectorHook<IRootState> = useSelector
export const useAppDispatch: DispatchFunc = useDispatch

export default store
