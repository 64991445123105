export const ReplaceURL = (countryCode: string) => {
  const domain = process.env.REACT_APP_STATIC_PAGE_DOMAIN

  const PRIVACYURL = domain + '/pages/privacy/' + countryCode + '/index.html'
  const NOTICEURL = domain + '/pages/notice/' + countryCode + '/index.html'

  return {
    privacyUrl: PRIVACYURL,
    noticeUrl: NOTICEURL,
  }
}
